(function ($) {

  // Ready
  $(document).ready(function () {


    // galerie
    if ($('.wp-block-gallery')) {
      $('.wp-block-gallery .wp-block-image a').attr('data-fancybox', 'gallery').fancybox();
    }


    // 404
    $('#power').on('click', function () {
      if ($(".img404-container").hasClass("on")) {
        $(".img404-container").removeClass("on");
      } else {
        $(".img404-container").addClass("on");
      }
    });

    // Hp > Number
    $.each($('.odometer'), function (i, val) {
      var odometer = new Odometer({
        el: $(this)[0],
        value: 0,
        theme: 'digital',
        duration: 2000,
        format: '(ddd)'
      });
      odometer.render();
    });

    // Scroll
    $(window).on('scroll', function () {
      if ($(this).scrollTop() >= 70) {
        $("#header").addClass("is-sticky");
      } else {
        $("#header").removeClass("is-sticky");
      }

      // Hp > Number
      if ($(".home-numbers").length) {
        if (($(window).scrollTop() + $(".home-numbers").innerHeight()) >= $(".home-numbers").offset().top) {
          $.each($('.odometer'), function (i, val) {
            var num = $(this).data('num');
            $(this).text(num);
          });
        }
      }

    });

  });

  // Load
  $(window).on('load', function () {
    var vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', vh + 'px');


    // Archive container + filters + load more
    var $grid = $('.archive-grid').isotope({
      itemSelector: '.grid-item',
      // filter: ($('.filter-button-group').data('taxactive') !== "") ? "." + $('.filter-button-group').data('taxactive') : "",
      masonry: {
        columnWidth: '.grid-sizer',
        gutter: 30
      },
    });
    $('.filter-button-group').on('click', 'button', function () {
      var filterValue = $(this).attr('data-filter');
      $grid.isotope({ filter: filterValue });
      $('.filter').removeClass('active');
      $(this).addClass('active');
    });

    $('.btn-loadmore').on('click', function () {
      var button = $(this),
        type = button.data('type'),
        titre = button.data('titre'),
        data = {
          'action': type + '_loadmore',
          'query': loadmore_params.posts,
          'page': loadmore_params.current_page
        };

      $.ajax({
        url: loadmore_params.ajaxurl,
        data: data,
        type: 'POST',
        beforeSend: function (xhr) {
          button.text('Tournage en cours ...');
        },
        success: function (data) {
          if (data) {
            button.text('Encore plus de ' + titre + ' !');
            $('.archive-grid').append(data);
            loadmore_params.current_page++;

            if (loadmore_params.current_page == loadmore_params.max_page)
              button.remove();
          } else {
            button.remove();
          }

          $grid.isotope('reloadItems').isotope();
        }
      });
    });


    // HP > banner
    var swiperCat = new Swiper('.swiper-home-banner', {
      speed: 400,
      effect: 'fade',
      pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      slidesPerView: 1,
    });

    // HP > avis
    var swiperCat = new Swiper('.swiper-home-avis', {
      speed: 400,
      slidesPerView: 'auto',
      loop: true,
      autoplay: true,
      pagination: {
        el: '.swiper-pagination',
        type: 'bullets',
        clickable: true,
      },
    });

    // Menu burger
    $(".burger-menu").click(function () {
      $(".header-menu").toggleClass("menu-open");
      $(this).toggleClass("crossed");



      if ($('.header-menu').hasClass("menu-open")) {
        $('body').css('overflowY', 'hidden');
        $('.sticky-socials').css('opacity', '1');
      } else {
        $('body').css('overflowY', 'auto');
        $('.sticky-socials').css('opacity', '0');
      }
    });

  });




})(jQuery);


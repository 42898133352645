document.addEventListener("DOMContentLoaded", function () {
  // Vérifier si l'élément avec l'ID "gmap" existe
  const gmapElement = document.getElementById('gmap');
  if (gmapElement) {
    // Initialiser la carte
    const map = L.map('gmap').setView([46.1505116, 6.6677702], 16);

    // Ajouter une couche de tuiles
    L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
      maxZoom: 19,
      attribution: '© OpenStreetMap contributors'
    }).addTo(map);

    // Ajouter un marqueur avec un popup
    const markerIcon = L.icon({
      iconUrl: '/wp-content/themes/mojopress/assets/img/marker.svg',
      iconSize: [32, 32],
      iconAnchor: [16, 32],
      popupAnchor: [0, -32]
    });

    L.marker([46.1505116, 6.6677702], { icon: markerIcon })
      .addTo(map)
      .bindPopup('<b>Lucas Stanus Production</b>')
      .openPopup();

    // Désactiver le zoom par défilement
    map.scrollWheelZoom.disable();

    // Désactiver le double-clic pour zoomer
    map.doubleClickZoom.disable();
  }
});